<template>
  <div class="add-order">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="form-container"
    >
        <el-form-item class="search-item" label="用户名">
            <el-input v-model="ruleForm.name" placeholder="用户名"></el-input>
        </el-form-item>
        <el-form-item class="search-item" label="密码">
            <el-input v-model="ruleForm.password" placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item class="search-item" label="剩余金币">
            <el-input v-model="ruleForm.balance" placeholder="剩余金币"></el-input>
        </el-form-item>

      <el-form-item>
          <el-button  @click="submitForm()" type="primary">确认添加</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {getRsaCode} from "@/utils/utils";

export default {
  data() {
    return {
      ruleForm: {
        name: '',
        password: '',
        expireDate: '',
        balance: '',
      },
      rules: {},
    };
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    submitForm() {

        let postData = {...this.ruleForm}
        postData.password = getRsaCode(postData.password)
        postData.balance = Number(postData.balance)
        this.$http.post('/admin/user/addUser', postData).then(res => {
            if (res.code === 0) {
                this.$message({
                    message: "成功",
                    type: "success",
                });
                this.resetForm()
                this.$router.push({ path: "/admin/userList"}).then(() => {
                    // 获取指定路由对象
                    try {
                        let matchedRoute = this.$route.matched.find(route => route.path === '/admin/userList');
                        matchedRoute.instances.default.$refs.normalTable.getList()
                    } catch (e) {
                        console.log(e)
                    }
                });
            }
        })
    },
    resetForm() {
      this.$refs['ruleForm'].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.add-order {
    margin-top: 20px;
}
.textarea{
    max-width: 400px;
    margin-bottom: 20px;
    margin-left:20px
}
.title {
    font-size: 13px;
    margin-left: 20px;
    .demo {
        padding: 10px;
        border: solid 1px #eee;
        width: 150px;
        margin-bottom: 10px;
    }
}

@media (max-width: 450px) {
    .textarea{
        max-width: 90%;
        margin: 0 10px 10px 10px;
    }
}

::v-deep .el-form-item__label {
    padding-right: 15px;
}
</style>
